import React from 'react';
import Select from 'react-select';
import {FieldContainer} from "./FieldContainer";

export function SelectField({name, value, label, onChange, column, row, required, hideLabel, disabled, options, isMulti= false, errorMessages, placeholder, isClearable = true, rightButton}) {
  const handleChange = (selectedOption) => {
    if (isMulti) {
      onChange(name, selectedOption?.map(option=>option.value)??[]);
    } else {
      onChange(name, selectedOption?.value ?? "");
    }
  };

  const customWeight = (state) => {
    if (options(name)?.[0]?.options?.length) {
      const parents = options(name)?.map(option => option?.options?.[0]?.value)
      if (parents.includes(state?.data?.value)) {
        return "bold"
      }
    }
    return 'normal'
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontWeight: customWeight(state),
    }),
  };
  return (
    <FieldContainer {...{name, label: label? label(name):'', column, row, required, hideLabel, errorMessages, inputClassName: rightButton ? 'd-flex' : ''}}>
      {rightButton}
      <Select
        className={`custom-input ${disabled ? 'disabled' : ''}`}
        options={options(name)}
        menuPlacement="auto"
        {...(isMulti ? {isMulti} : {})}
        onChange={handleChange}
        value={value(name)}
        isDisabled={disabled}
        placeholder={placeholder}
        noOptionsMessage={() => 'Нет доступных опций'}
        isClearable={isClearable}
        styles={customStyles}
      />
    </FieldContainer>
  );
}
