import uniFetch from "./uniFetch";
import {QUERY_LIMIT} from "../constants";

export function getStamp(){
    const date = Date.now()
    return 'ts='+Math.floor(date / 1000)
  }

export function isDateExpired(isoDate, dela_days=1){
    if(!isoDate) return false;
    const date = new Date(isoDate);
    const current_date = Date.now();
    const delta = 86400000 * dela_days

    return date.toString() !=="Invalid Date" ? date.getTime() - current_date < delta: false
}


export function convertDateFormat(isoDate) {
    if(!isoDate) return '';
    const date = new Date(isoDate);
    if (date.toString() ==="Invalid Date") return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
}


export function getCurrentStartYear() {
    const current_date = new Date(Date.now());
    const year = current_date.getFullYear();
    const str_date = `${year}-01-01T00:01`;
    return str_date;
}


export const fetchDatamart = (value, slug, key) => {
    const param = value ? `&${key}=${value}` : '';

    return uniFetch(`/edw/api/data-marts/${slug}/entities/?format=json&limit=${QUERY_LIMIT}${param}`).then(data => {
        const options = data.results.objects.map(item => ({value: item.id, label: item.entity_name}));
        return options;
    });
  };


export const loadOptions = (inputValue, callback, slug, key) => {
    fetchDatamart(inputValue, slug, key).then(options =>  callback(options));
};

export function getSelectOptionFromSlug(options, slug) {
  if(Array.isArray(slug)){
    return options?.filter(option=>slug.includes(option.value))
  } else {
    return options?.find(option=>slug===option.value)??[]
  }
}

export function getSelectOptionFromSlugOrCreated(options, slug) {
  if(Array.isArray(slug)){
    return options?.filter(option=>slug.includes(option.value))
  } else {
    const exist_option = options?.find(option=>slug===option.value)
    if(exist_option){
      return exist_option
    } else {
      return {value: slug, label: slug}
    }
    return options?.find(option=>slug===option.value)??[]
  }
}

export function formatDateToSelectOptions(data) {
  return data?.map(item=>{return {value: item.slug, label:item.name}})
}

export function filteredFields(fields, filter, definition = {}) {
  return Object.keys(filter).reduce((acc, field) => {
    if (fields.hasOwnProperty(field)) {
      if (Array.isArray(fields[field]) && fields[field].length === 0 && definition[field]?.default) {
        acc[field] = definition[field]?.default;
      } else {
        acc[field] = fields[field] || definition[field]?.default;
      }
    }
    return acc;
  }, {});
}

export function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(6, '0');

    const timezoneOffset = -now.getTimezoneOffset();
    const timezoneHours = String(Math.floor(timezoneOffset / 60)).padStart(2, '0');
    const timezoneMinutes = String(timezoneOffset % 60).padStart(2, '0');
    const timezoneSign = timezoneOffset >= 0 ? '+' : '-';

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${timezoneSign}${timezoneHours}:${timezoneMinutes}`;

    return formattedDateTime;
}

export function getCurrentDate() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');

    const formattedDateTime = `${year}-${month}-${day}`;

    return formattedDateTime;
}

export function transformData(data) {
  return data?.children?.map(group => ({
    options: [{value: group.slug, label: group.name},...group.children.map(item => ({
      value: item.slug,
      label: item.name
    }))]
  }));
};

export function findElementsByValues(data, values) {
  const foundElements = [];
  if (!data && !values) return foundElements;
  for (let group of data) {
    for (let item of group?.options) {
      if (values?.includes(item.value)) {
        foundElements?.push(item);
      }
    }
  }

  return foundElements;
};
